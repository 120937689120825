/* ========================================================================
 * LAZY LOADING
 * ======================================================================== */
(function($) {

  function bluLazyLoad($item) {
    var src = $item.data('src') || '';
    var srcset = $($item).data('srcset') || '';

    $item.attr({
      'src': src,
      'srcset': srcset
    })
    .removeClass('preview')
    .addClass('loaded');
  }

  $(document).ready(function() {
    var $items = $('.lazy-item');

    $items.each(function () {
      var $anim = $(this);
      var animItem = scrollMonitor.create($anim);
      animItem.lock();

      animItem.enterViewport(function() {
        bluLazyLoad($anim);
      });
    });


  });
})(jQuery); // Fully reference jQuery after this point.
