/* ========================================================================
 * HAMBURGER - https://jonsuh.com/hamburgers/
 * ======================================================================== */
(function($) {
  $(document).ready(function() {
    var $hamburger = $(".hamburger");
    $hamburger.on("click", function(e) {
      $hamburger.toggleClass("is-active");
    });
  });
})(jQuery); // Fully reference jQuery after this point.
