/* ========================================================================
 * SCRIPTS BLULAB
 * ======================================================================== */
(function ($) {

    // Altezza dello schermo
    function above_fold_height() {
        // window - header - adminbar
        return $(window).height() - $('#site-header').outerHeight();
    }

    // Calcolo altezza dello slider in home
    function adapt_home_img_height() {
        $('.full-size-slider').css({
            'height': above_fold_height()
        });
        $('.full-size-slider .container-spinner').css({
            'height': above_fold_height()
        });
        $('.full-size-slider .slide').css({
            'height': above_fold_height()
        });
    }

    $(document).ready(function () {

        //Click event to scroll to top
        $('.go-top').click(function () {
            $('html, body').animate({scrollTop: 0}, 800);
            return false;
        });

        // MATCH HEIGHT
        $('.equal-el').matchHeight({
            byRow: true,
            property: 'height',
            target: null,
            remove: false
        });

        // Funzione per lo scroll a -50px dai filtri del Portfolio in evidenza
        $('.portfolio-filter--list-featured').click(function (e) {
            // prevent default action
            e.preventDefault();
            scrollToElement($(this), 1000);
        });

        $('.masonry-wrapper').imagesLoaded().always(function (instance) {
            //Masonry per  Lista pubblicazioni
            $('.masonry-wrapper').masonry({
                itemSelector: '.masonry-item'
            });
        });

        // SLIDER HOME
        $('.full-size-slider .slides').on('init', function (slick) {
            adapt_home_img_height();
            $('.full-size-slider .slides').fadeIn('fast');
            $(this).removeClass('cS-hidden');
            $('.full-size-slider .container-spinner').fadeOut('fast');
        }).slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
            speed: 800,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            mobileFirst: true,
            autoplay: true,
            autoplaySpeed: 2400,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

        // SLIDER HOME // Mobile
        $('.default-slider .slides').on('init', function (slick) {
            $('.default-slider .slides').fadeIn('fast');
            $(this).removeClass('cS-hidden');
        }).slick({
            lazyLoad: 'ondemand',
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            infinite: true,
            speed: 800,
            fade: true,
            cssEase: 'linear',
            arrows: false,
            mobileFirst: true,
            autoplay: true,
            autoplaySpeed: 2400,
            pauseOnFocus: false,
            pauseOnHover: false,
        });

        // SLIDER PRODOTTI IN HOME
        $('.product-slider .slides').on('init', function (slick) {
            $('.product-slider .slides').fadeIn('fast');
            $(this).removeClass('cS-hidden');
        }).slick({
            slidesToShow: 6,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            prevArrow: '<div class="slide-button slide-button-prev"><span class="fa fa-angle-left"></span></div>',
            nextArrow: '<div class="slide-button slide-button-next"><span class="fa fa-angle-right"></span></div>',
            responsive: [
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
                }
            ]
        });
    });

    $(window).load(function () {
        // Home IMG
        adapt_home_img_height();

        // Isotope
        var $grid = $('.isotope-list').isotope({
            itemSelector: '.grid-item',
            layoutMode: 'masonry'
        });

        // filter items on button click
        $('.filter-item').on('click', function (e) {
            e.preventDefault();
            $('.filter-item').removeClass('active');
            $(this).toggleClass('active');
            var filterValue = $(this).data('filter');
            $grid.isotope({filter: filterValue});
        });

    });

    $(window).resize(function () {
        // Home IMG
        adapt_home_img_height();
    });

    //Funzione rendere visibile e trasparente l'header quando si scrolla la pagina
    $(window).scroll(function () {

        var scrollTop = $(window).scrollTop();

        if (scrollTop > 250) {
            $('.go-top').fadeIn();
        } else {
            $('.go-top').fadeOut();
        }

    });

}(jQuery)); // Fully reference jQuery after this point.
